'use client'

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Cell, Divider, Dialog, Navigation, Spacer } from '@vinted/web-ui'

import useAbTest from 'hooks/useAbTest'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import InfoBanner from 'components/InfoBanner'
import ScrollableArea from 'components/ScrollableArea'
import { ItemSelection } from 'components/Bumps'
import { ExtraServicePolicyNote, ExtraServiceActivePayInMethod } from 'components/ExtraService'

import { State as AppState } from 'state/types'

import { ExtraServiceOrderStatus } from 'constants/extra-service'
import { InfoBannerScreen } from 'constants/info-banner'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { BumpOptionModel, BumpOrderModel } from 'types/models'

import { clickEvent, viewScreenEvent } from 'libs/common/event-tracker/events'
import { getPayButtonTitle } from 'libs/utils/checkout'

import {
  getPaymentStatus,
  getWalletBalance,
  getActivePayInMethod,
  getSelectedCreditCard,
} from 'state/extra-service/selectors'

import OrderSummary from './OrderSummary'

type Props = {
  show: boolean
  bumpOrder?: BumpOrderModel
  isOrderFree?: boolean
  selectedOption: BumpOptionModel | null
  onBack: () => void
  onConfirmOrder: () => void
  onShowSalesTaxModal: () => void
  onPaymentMethodSelect: () => void
}

const BumpOrderConfirmationModal = ({
  show,
  bumpOrder,
  isOrderFree,
  selectedOption,
  onBack,
  onConfirmOrder,
  onShowSalesTaxModal,
  onPaymentMethodSelect,
}: Props) => {
  const translate = useTranslate('bump_items.order_confirmation_modal')
  const translatePayInMethodError = useTranslate('payment_method_selection.errors')
  const translatePayButton = useTranslate('checkout.purchase')
  const { track } = useTracking()

  const [payInMethodError, setPayInMethodError] = useState<string>()

  const walletBalance = useSelector(getWalletBalance)
  const orderStatus = useSelector(getPaymentStatus)
  const selectedCreditCard = useSelector(getSelectedCreditCard)

  const payButtonUnificationAbTest = useAbTest({
    abTestName: 'pay_button_unification',
    shouldTrackExpose: true,
  })

  const activePayInMethod = useSelector((state: AppState) =>
    getActivePayInMethod(state, bumpOrder?.payable.amount),
  )

  const isConfirmationDisabled = !bumpOrder || bumpOrder.isTaxCoverageAvailable === false

  const isOrderValid = !!activePayInMethod || isOrderFree

  useEffect(() => {
    if (!show) return

    track(
      viewScreenEvent({
        screen: Screen.PushUpOrderConfirmation,
      }),
    )
  }, [show, track])

  useEffect(() => {
    if (isOrderValid && payInMethodError) {
      setPayInMethodError(undefined)
    }
  }, [isOrderValid, payInMethodError])

  function handleShowSalesTaxModal() {
    onShowSalesTaxModal()

    track(
      clickEvent({
        target: ClickableElement.SalesTaxInfo,
        screen: Screen.PushUpOrderConfirmation,
        targetDetails: JSON.stringify({
          vas_order_id: bumpOrder?.id,
        }),
      }),
    )
  }

  function renderTitle() {
    return (
      <>
        <Cell>
          <Navigation body={translate('order_summary.title')} />
        </Cell>
        <div className="u-padding-horizontal-medium">
          <InfoBanner screen={InfoBannerScreen.VASCheckout} />
        </div>
        <Divider />
      </>
    )
  }

  function renderOrderSummary() {
    if (!bumpOrder || !selectedOption) return null

    return (
      <OrderSummary
        order={bumpOrder}
        selectedOption={selectedOption}
        onShowSalesTaxModal={handleShowSalesTaxModal}
      />
    )
  }

  function trackOrderConfirmation() {
    if (!bumpOrder) return

    const confirmOrderEventTarget =
      bumpOrder.pushUpOrderItems.length > 1
        ? ClickableElement.ConfirmMultipleItemsPushUp
        : ClickableElement.ConfirmItemPushUp

    track(
      clickEvent({
        screen: Screen.PushUpOrderConfirmation,
        target: confirmOrderEventTarget,
      }),
    )
  }

  function handleConfirmOrder() {
    if (!isOrderValid) {
      setPayInMethodError(translatePayInMethodError('not_selected_payment_method'))

      return
    }

    trackOrderConfirmation()
    onConfirmOrder()
  }

  function renderBody() {
    if (!bumpOrder) return null

    return (
      <>
        <ItemSelection canAddItems={false} orderItems={bumpOrder.pushUpOrderItems} />
        <Divider />
        {renderOrderSummary()}
        <Divider />
        {!isOrderFree && (
          <>
            <ExtraServiceActivePayInMethod
              error={payInMethodError}
              walletBalance={walletBalance}
              activePayInMethod={activePayInMethod}
              selectedCreditCard={selectedCreditCard}
              onPaymentMethodSelect={onPaymentMethodSelect}
            />
            <Divider />
          </>
        )}
        <ExtraServicePolicyNote />
        <Spacer size={Spacer.Size.Large} />
      </>
    )
  }

  function renderActions() {
    const buttonTitle = getPayButtonTitle(payButtonUnificationAbTest)

    return (
      <Cell>
        <Button
          disabled={isConfirmationDisabled}
          isLoading={orderStatus === ExtraServiceOrderStatus.Processing}
          text={buttonTitle ? translatePayButton(buttonTitle) : translate('actions.place_order')}
          styling={Button.Styling.Filled}
          onClick={handleConfirmOrder}
          testId="bump-place-order-button"
        />
        <Spacer />
        <Button styling={Button.Styling.Flat} text={translate('actions.back')} onClick={onBack} />
      </Cell>
    )
  }

  return (
    <Dialog show={show} hasScrollableContent testId="bump-order-confirmation-modal">
      <div className="u-fill-width">
        {renderTitle()}
        <ScrollableArea>
          {renderBody()}
          {renderActions()}
        </ScrollableArea>
      </div>
    </Dialog>
  )
}

export default BumpOrderConfirmationModal
