'use client'

import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Button, Cell, Dialog, Spacer, Text } from '@vinted/web-ui'
import { QuestionCircle24 } from '@vinted/monochrome-icons'

import SeparatedList from 'components/SeparatedList'
import ScrollableArea from 'components/ScrollableArea'
import { ItemSelection } from 'components/Bumps'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import useAbTest from 'hooks/useAbTest'
import useSession from 'hooks/useSession'
import useSystemConfiguration from 'hooks/useSystemConfiguration'

import { formatCurrencyAmount } from 'libs/utils/formatString'
import { abTestExposeEvent, clickEvent, viewScreenEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import { BumpOptionModel, BumpOptionsModel, BumpOrderModel } from 'types/models'

import BumpOptionList from './BumpOptionList'
import SingleBumpOptionInfo from './SingleBumpOptionInfo'

type Props = {
  canAddItems?: boolean
  show: boolean
  bumpOrder?: BumpOrderModel
  isOrderLoading: boolean
  isBumpOptionsLoading: boolean
  bumpOptions?: BumpOptionsModel
  selectedOption: BumpOptionModel | null
  onSelectedOptionChange: (selectedBumpOption: BumpOptionModel) => void
  onAddButtonClick: () => void
  onConfirm: () => void
  onCancel: () => void
  onHelpButtonClick: () => void
}

const BumpSelectionConfirmationModal = ({
  canAddItems = true,
  show,
  bumpOrder,
  isOrderLoading,
  isBumpOptionsLoading,
  bumpOptions,
  selectedOption,
  onSelectedOptionChange,
  onCancel,
  onConfirm,
  onAddButtonClick,
  onHelpButtonClick,
}: Props) => {
  const { locale } = useIntl()
  const { user: currentUser, anonId } = useSession()
  const countryCode = useSystemConfiguration()?.userCountry
  const internationalBumpPricingAbTest = useAbTest({
    abTestName: 'international_bump_pricing',
  })
  const bumpPricingRoundingAbTest = useAbTest({
    abTestName: 'bump_pricing_rounding',
  })

  const { track } = useTracking()
  const translate = useTranslate('bump_items.selection_confirmation_modal')
  const a11yTranslate = useTranslate('bump_items.a11y')

  useEffect(() => {
    if (!show) return

    track(
      viewScreenEvent({
        screen: Screen.PushUpOrderReview,
      }),
    )

    if (!anonId || !countryCode) return

    const exposee = { userId: currentUser?.id, anonId, countryCode }

    if (internationalBumpPricingAbTest)
      track(abTestExposeEvent({ ...exposee, ...internationalBumpPricingAbTest }))

    if (bumpPricingRoundingAbTest)
      track(abTestExposeEvent({ ...exposee, ...bumpPricingRoundingAbTest }))
  }, [
    countryCode,
    currentUser,
    anonId,
    bumpPricingRoundingAbTest,
    internationalBumpPricingAbTest,
    show,
    track,
  ])

  function handleConfirm() {
    if (isOrderLoading || !selectedOption) return

    onConfirm()

    track(
      clickEvent({
        screen: Screen.PushUpOrderReview,
        target: ClickableElement.ReviewPushupOrder,
        targetDetails: selectedOption.days.toString(),
      }),
    )
  }

  function handleCancel() {
    onCancel()

    track(
      clickEvent({
        screen: Screen.PushUpOrderReview,
        target: ClickableElement.CloseScreen,
      }),
    )
  }

  function handleAddButtonClick() {
    onAddButtonClick()
    track(
      clickEvent({
        screen: Screen.PushUpOrderReview,
        target: ClickableElement.MultiplePushUp,
      }),
    )
  }

  function handleHelpButtonClick() {
    onHelpButtonClick()
    track(
      clickEvent({
        screen: Screen.PushUpOrderReview,
        target: ClickableElement.PushUpLearnMode,
      }),
    )
  }

  function handleBumpOptionChange(selectedBumpOption: BumpOptionModel) {
    onSelectedOptionChange(selectedBumpOption)
    track(
      clickEvent({
        screen: Screen.PushUpOrderReview,
        target: ClickableElement.PushUpDurationChange,
        targetDetails: JSON.stringify({
          dutation: selectedBumpOption.days.toString(),
          is_international: selectedBumpOption.international,
        }),
      }),
    )
  }

  function getOrderPriceLabel() {
    return selectedOption ? formatCurrencyAmount(selectedOption.payable, locale) : ''
  }

  function renderTitle() {
    return (
      <Cell
        styling={Cell.Styling.Narrow}
        prefix={<Spacer orientation={Spacer.Orientation.Horizontal} size={Spacer.Size.X3Large} />}
        suffix={
          <Button
            iconName={QuestionCircle24}
            styling={Button.Styling.Flat}
            onClick={handleHelpButtonClick}
            testId="bump-help-button"
            aria={{ 'aria-label': a11yTranslate('actions.learn_more') }}
          />
        }
      >
        <div className="u-text-center">
          <Text
            id="bump_selection_modal_title"
            type={Text.Type.Title}
            alignment={Text.Alignment.Center}
            text={translate('title')}
          />
        </div>
      </Cell>
    )
  }

  function renderMotivation() {
    if (!bumpOptions?.motivation) return null

    return (
      <Cell styling={Cell.Styling.Narrow}>
        <Text
          format
          type={Text.Type.Subtitle}
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
          text={bumpOptions.motivation.description}
          theme={bumpOptions.motivation.highlight ? 'success' : undefined}
        />
      </Cell>
    )
  }

  function renderBumpOptions() {
    if (!bumpOptions) return null

    const { options } = bumpOptions

    if (options.length > 1 && selectedOption)
      return (
        <BumpOptionList
          bumpOptions={options}
          selectedBumpOption={selectedOption}
          onSelect={handleBumpOptionChange}
        />
      )

    if (options.length === 1) {
      return <SingleBumpOptionInfo bumpOption={options[0]!} />
    }

    return null
  }

  function renderSalesTaxNote() {
    if (!bumpOrder?.salesTax) return null

    return (
      <Text
        alignment={Text.Alignment.Center}
        text={translate('duration.details.sales_tax_note')}
        testId="bump-selection-confirmation-modal--sales-tax-note"
        width={Text.Width.Parent}
      />
    )
  }

  function renderDurationOptionsInfo() {
    return (
      <div className="u-ui-padding-large u-padding-top-none">
        <SeparatedList separator={<Spacer orientation={Spacer.Orientation.Horizontal} />}>
          {renderBumpOptions()}
          {renderSalesTaxNote()}
        </SeparatedList>
      </div>
    )
  }

  function renderBody() {
    return (
      <div className="u-fill-width">
        <ItemSelection
          canAddItems={canAddItems}
          orderItems={selectedOption?.pushUpOrderItems}
          onAddButtonClick={handleAddButtonClick}
        />
        {renderDurationOptionsInfo()}
      </div>
    )
  }

  function renderActions() {
    const amount = getOrderPriceLabel()

    return (
      <Cell>
        <Button
          isLoading={isOrderLoading || isBumpOptionsLoading}
          disabled={!selectedOption || isBumpOptionsLoading}
          styling={Button.Styling.Filled}
          text={translate('actions.review_order', { amount })}
          onClick={handleConfirm}
          testId="bump-review-order-button"
        />
        <Spacer />
        <Button
          styling={Button.Styling.Flat}
          text={translate('actions.cancel')}
          aria={{ 'aria-label': a11yTranslate('actions.close') }}
          onClick={handleCancel}
        />
      </Cell>
    )
  }

  return (
    <Dialog
      aria={{ labelledby: 'bump_selection_modal_title' }}
      show={show}
      hasScrollableContent
      testId="bump-selection-confirmation-modal"
    >
      <div className="u-fill-width">
        {renderTitle()}
        <ScrollableArea>
          {renderMotivation()}
          {renderBody()}
        </ScrollableArea>
        {renderActions()}
      </div>
    </Dialog>
  )
}

export default BumpSelectionConfirmationModal
