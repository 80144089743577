'use client'

import { Fragment } from 'react'

import List from 'components/List'
import { BumpOptionModel } from 'types/models'

import BumpOptionListItem from './BumpOptionListItem'

type Props = {
  selectedBumpOption: BumpOptionModel
  bumpOptions: Array<BumpOptionModel>
  onSelect: (selectedOption: BumpOptionModel) => void
}

const BumpOptionList = ({ onSelect, bumpOptions, selectedBumpOption }: Props) => {
  const handleOptionSelect = (option: BumpOptionModel) => () => {
    onSelect(option)
  }

  return (
    <List>
      {bumpOptions.map(option => (
        <Fragment key={option.id}>
          <BumpOptionListItem
            bumpOption={option}
            onSelect={handleOptionSelect(option)}
            isSelected={option.id === selectedBumpOption.id}
          />
        </Fragment>
      ))}
    </List>
  )
}

export default BumpOptionList
