'use client'

import { ReactNode } from 'react'
import {
  Button,
  Cell,
  Divider,
  Icon,
  Image,
  Dialog,
  Navigation,
  Spacer,
  Text,
} from '@vinted/web-ui'
import { Bump24, Chart24, PriceTag24, TargetArrow24, X24 } from '@vinted/monochrome-icons'
import { IconType } from '@vinted/web-ui/dist/types/lib/Icon/Icon'

import FaqEntryUrl from 'components/FaqEntryUrl'
import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import { FaqEntryType } from 'constants/faq-entry'
import { AccessChannel } from 'constants/index'
import SeparatedList from 'components/SeparatedList'

type Props = {
  show: boolean
  hasLocalOptions?: boolean
  onCloseOrConfirm: () => void
}

type ContentRow = {
  icon: IconType
  text: string
}

const BumpValuePropositionModal = ({ show, hasLocalOptions, onCloseOrConfirm }: Props) => {
  const translate = useTranslate('bump_items.value_proposition_modal_v2')
  const a11yTranslate = useTranslate('bump_items.a11y')
  const asset = useAsset('/assets/bumps')

  const learnMoreTranslationValues = {
    'help-center': (chunks: Array<ReactNode>) => (
      <FaqEntryUrl
        type={FaqEntryType.Bump}
        accessChannel={AccessChannel.ProductLink}
        render={url => (
          <a href={url} target="_blank" rel="noreferrer">
            {chunks}
          </a>
        )}
      />
    ),
  }

  const contentRows: Array<ContentRow> = [
    { icon: Bump24, text: translate('body.one') },
    { icon: TargetArrow24, text: translate('body.two') },
    { icon: Chart24, text: translate('body.three') },
    { icon: PriceTag24, text: translate(`body.four${hasLocalOptions ? '_international' : ''}`) },
  ]

  const handleOnClose = () => {
    onCloseOrConfirm()
  }

  const renderModalBody = () => {
    const imageSrc = asset('new-value-proposition.png', {
      theme: { dark: 'new-value-proposition-dark.png' },
    })
    const imageSrc2x = asset('new-value-proposition@2x.png', {
      theme: { dark: 'new-value-proposition-dark@2x.png' },
    })
    const imageSrcSet = `${imageSrc} 1x, ${imageSrc2x} 2x`

    return (
      <div className="u-fill-width">
        <Navigation
          body={translate('title')}
          right={
            <Button
              inverse
              inline
              aria={{ 'aria-label': a11yTranslate('actions.close') }}
              iconName={X24}
              onClick={handleOnClose}
            />
          }
        />
        <Divider />
        <Image
          src={imageSrc}
          srcset={imageSrcSet}
          scaling={Image.Scaling.Cover}
          ratio={Image.Ratio.Landscape}
        />
        <Cell styling={Cell.Styling.Wide}>
          <SeparatedList separator={<Spacer size={Spacer.Size.Large} />}>
            {contentRows.map(({ icon, text }) => (
              <div key={`body-bullet-${icon.Title}`} className="u-flexbox u-gap-medium">
                <Icon color={Icon.Color.GreyscaleLevel4} name={icon} title={icon.Title} />
                <Text html text={text} type={Text.Type.Body} />
              </div>
            ))}
          </SeparatedList>
          <Spacer size={Spacer.Size.X2Large} />
          <Text type={Text.Type.Body}>
            {translate('actions.learn_more', learnMoreTranslationValues)}
          </Text>
          <Spacer size={Spacer.Size.X2Large} />
          <Button
            text={translate('actions.confirm')}
            styling={Button.Styling.Filled}
            size={Button.Size.Medium}
            onClick={onCloseOrConfirm}
            testId="bump-confirm-button"
          />
        </Cell>
      </div>
    )
  }

  return (
    <Dialog hasScrollableContent defaultCallback={handleOnClose} show={show}>
      {renderModalBody()}
    </Dialog>
  )
}

export default BumpValuePropositionModal
