'use client'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Dialog } from '@vinted/web-ui'

import { urlWithParams } from 'libs/utils/url'
import { navigateToPage, reloadPage } from 'libs/utils/window'
import { navigateToSingleCheckout } from 'libs/utils/checkout'
import { viewSingleCheckoutEvent } from 'libs/common/event-tracker/events'

import { initiateSingleCheckout } from 'data/api'

import {
  ExtraServiceOrderType,
  ExtraServiceCheckoutModal,
  CheckoutOrderTypeMap,
} from 'constants/extra-service'
import { BUMP_MULTIPLE_ITEM_SELECTION_URL } from 'constants/routes'
import { AbTestVariant } from 'constants/abtest'
import { UiState } from 'constants/ui'
import { PromoAfterItemUpload } from 'state/profile/constants'

import GenericErrorModal from 'pages/SingleCheckout/components/GenericErrorModal'

import { ExtraServiceCheckout } from 'components/ExtraService'
import ContentLoader from 'components/ContentLoader'
import useBumpCheckoutContext from 'hooks/useBumpCheckoutContext'

import * as profileSelectors from 'state/profile/selectors'
import { actions as profileActions } from 'state/profile/slice'

import useAbTest from 'hooks/useAbTest'
import useTracking from 'hooks/useTracking/useTracking'

import useBumpOrder from './hooks/useBumpOrder'
import useBumpOptions from './hooks/useBumpOptions'
import BumpSelectionConfirmationModal from './BumpSelectionConfirmationModal'
import BumpOrderConfirmationModal from './BumpOrderConfirmationModal'
import BumpValuePropositionModal from './BumpValuePropositionModal'
import BumpOrderErrorModal from './BumpOrderErrorModal'

type Props = {
  isInitiatedFromMultiSelect?: boolean
  onCheckoutSuccess?: () => void
}

const BumpCheckout = ({
  isInitiatedFromMultiSelect = false,
  onCheckoutSuccess = reloadPage,
}: Props) => {
  const { track } = useTracking()
  const dispatch = useDispatch()
  const { selectedItemIds, activeModal, setActiveModal, setSelectedItemIds } =
    useBumpCheckoutContext()

  const isUnifiedCheckoutEnabled =
    useAbTest({ abTestName: 'single_vas_checkout_web' })?.variant === AbTestVariant.On

  const currentPromo = useSelector(profileSelectors.getCurrentPromotion)
  const uploadedItemId = useSelector(profileSelectors.getUploadedItemId)

  const [uiState, setUiState] = useState(UiState.Idle)
  const [isProceedToCheckoutClicked, setIsProceedToCheckoutClicked] = useState(false)

  const isValuePropositionModalActive = activeModal === ExtraServiceCheckoutModal.ValueProposition

  const { order, isOrderLoading, prepareBumpOrder, getBumpOrder } = useBumpOrder()

  const isOrderFree = order && parseFloat(order.payable.amount) <= 0

  const {
    bumpOptions,
    isBumpOptionsLoading,
    bumpOptionsError,
    selectedBumpOption,
    updateSelectedBumpOption,
  } = useBumpOptions()

  useEffect(
    function openPrecheckoutAfterUploadForm() {
      if (currentPromo !== PromoAfterItemUpload.BumpCheckout) return
      if (!uploadedItemId) return

      setSelectedItemIds([uploadedItemId])
      setActiveModal(ExtraServiceCheckoutModal.OrderReview)
      dispatch(
        profileActions.setCurrentPromotionAfterItemUpload({ promo: PromoAfterItemUpload.Empty }),
      )
    },
    [currentPromo, uploadedItemId, setSelectedItemIds, setActiveModal, dispatch],
  )

  useEffect(() => {
    if (!isUnifiedCheckoutEnabled) return

    const orderId = order?.id
    const orderType = CheckoutOrderTypeMap[ExtraServiceOrderType.PushUp]

    if (!orderId) return

    track(
      viewSingleCheckoutEvent({
        orderType,
        checkoutId: null,
        orderId: orderId.toString(),
      }),
    )

    const fetchCheckoutId = async () => {
      const response = await initiateSingleCheckout({
        id: orderId.toString(),
        type: orderType,
      })

      if ('errors' in response) {
        setUiState(UiState.Failure)

        setActiveModal(ExtraServiceCheckoutModal.GenericErrorModal)

        return
      }

      navigateToSingleCheckout(response.checkout.id, orderId, orderType)
    }

    fetchCheckoutId()
  }, [order, isUnifiedCheckoutEnabled, track, setActiveModal])

  useEffect(
    function openCheckoutModal() {
      if (isUnifiedCheckoutEnabled) return
      if (!order || isOrderLoading) return
      if (!isProceedToCheckoutClicked) return

      setActiveModal(ExtraServiceCheckoutModal.Checkout)
      setIsProceedToCheckoutClicked(false)
    },
    [isUnifiedCheckoutEnabled, order, isOrderLoading, isProceedToCheckoutClicked, setActiveModal],
  )

  function openSetupModal() {
    setActiveModal(ExtraServiceCheckoutModal.OrderReview)
  }

  function openValuePropositionModal() {
    setActiveModal(ExtraServiceCheckoutModal.ValueProposition)
  }

  function goToBumpPage() {
    navigateToPage(urlWithParams(BUMP_MULTIPLE_ITEM_SELECTION_URL, { item_ids: selectedItemIds }))
  }

  function closeCheckoutModal() {
    setActiveModal(ExtraServiceCheckoutModal.None)
  }

  function handleCheckoutFinish(isCheckoutSuccess: boolean) {
    closeCheckoutModal()

    if (isCheckoutSuccess) {
      onCheckoutSuccess()
    }
  }

  function handleCheckoutInitAfterCardAuth(orderId: number) {
    setActiveModal(ExtraServiceCheckoutModal.Checkout)

    getBumpOrder(orderId)
  }

  function handleConfirmSelection() {
    if (!selectedBumpOption) return

    prepareBumpOrder(selectedItemIds, selectedBumpOption.days, selectedBumpOption.international)
    setIsProceedToCheckoutClicked(true)

    if (isUnifiedCheckoutEnabled) {
      setUiState(UiState.Pending)
    }
  }
  if (bumpOptionsError) return <BumpOrderErrorModal bumpOptionsError={bumpOptionsError} />

  if (uiState === UiState.Pending) {
    return (
      <Dialog show>
        <ContentLoader testId="bump-checkout-type-loader" />
      </Dialog>
    )
  }

  return (
    <>
      <BumpSelectionConfirmationModal
        show={activeModal === ExtraServiceCheckoutModal.OrderReview}
        bumpOrder={order}
        isOrderLoading={isOrderLoading}
        bumpOptions={bumpOptions}
        isBumpOptionsLoading={isBumpOptionsLoading}
        selectedOption={selectedBumpOption}
        onAddButtonClick={isInitiatedFromMultiSelect ? closeCheckoutModal : goToBumpPage}
        onSelectedOptionChange={updateSelectedBumpOption}
        onConfirm={handleConfirmSelection}
        onCancel={closeCheckoutModal}
        onHelpButtonClick={openValuePropositionModal}
      />
      <BumpValuePropositionModal
        show={isValuePropositionModalActive}
        hasLocalOptions={bumpOptions?.hasLocalOptions}
        onCloseOrConfirm={openSetupModal}
      />
      <ExtraServiceCheckout
        show={activeModal === ExtraServiceCheckoutModal.Checkout && !isUnifiedCheckoutEnabled}
        orderType={ExtraServiceOrderType.PushUp}
        isOrderFree={isOrderFree}
        orderId={order?.id}
        orderPayable={order?.payable}
        addCardSourceParams={{
          type: ExtraServiceOrderType.PushUp,
          itemId: selectedItemIds[0],
        }}
        onBack={openSetupModal}
        onCheckoutFinish={handleCheckoutFinish}
        orderConfirmationModal={props => (
          <BumpOrderConfirmationModal
            show={props.show}
            onBack={props.onBack}
            onConfirmOrder={props.onConfirmOrder}
            onShowSalesTaxModal={props.onShowSalesTaxModal}
            onPaymentMethodSelect={props.onPaymentMethodSelect}
            bumpOrder={order}
            isOrderFree={isOrderFree}
            selectedOption={selectedBumpOption}
          />
        )}
        onInitStartAfterCardAuth={handleCheckoutInitAfterCardAuth}
      />
      <GenericErrorModal
        isShown={activeModal === ExtraServiceCheckoutModal.GenericErrorModal}
        onClose={closeCheckoutModal}
      />
    </>
  )
}

export default BumpCheckout
