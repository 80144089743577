'use client'

import { Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'
import { BumpOptionModel } from 'types/models'

type Props = {
  bumpOption: BumpOptionModel
}

const SingleBumpOptionInfo = ({ bumpOption }: Props) => {
  const translate = useTranslate('bump_items.selection_confirmation_modal')
  const count = bumpOption.days

  return <Text text={translate('duration.days', { count }, { count })} />
}

export default SingleBumpOptionInfo
