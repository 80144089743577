'use client'

import { MouseEvent, useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Image, Text, Spacer, Icon } from '@vinted/web-ui'
import { X12, PlusSquareOutlined24 } from '@vinted/monochrome-icons'
import { keyBy, filter } from 'lodash'
import classNames from 'classnames'

import useTranslate from 'hooks/useTranslate'
import useSession from 'hooks/useSession'
import useIsDarkModeEnabledFromCookies from 'hooks/useIsDarkModeEnabledFromCookies'

import { BUMP_MULTIPLE_ITEM_SELECTION_URL } from 'constants/routes'

import HorizontalScrollArea from 'components/HorizontalScrollArea'

import { urlWithParams } from 'libs/utils/url'
import { formatCurrencyAmount } from 'libs/utils/formatString'

import { getUserItems } from 'data/api'
import { transformUserItemsResponse } from 'data/api/transformers/response'

import { BumpableItemModel, BumpOrderItemModel } from 'types/models'
import { getBumpableItemDataFromModel } from 'data/utils/bump'
import useBumpCheckoutContext from 'hooks/useBumpCheckoutContext'

type Props = {
  onAddButtonClick?: () => void
  orderItems: Array<BumpOrderItemModel> | undefined
  canAddItems?: boolean
  isOrderLoading?: boolean
}

const ItemSelection = ({
  onAddButtonClick,
  orderItems = [],
  canAddItems = true,
  isOrderLoading = false,
}: Props) => {
  const { locale } = useIntl()
  const translate = useTranslate('bump_items')
  const { user: currentUser } = useSession()
  const isDarkMode = useIsDarkModeEnabledFromCookies()

  const {
    selectedItemIds,
    setSelectedItemIds,
    getSelectedItemThumbnails,
    updateItemThumbnailsById,
  } = useBumpCheckoutContext()
  const selectedItemsThumbnails = getSelectedItemThumbnails()

  const fetchUserItem = useCallback(async () => {
    if (!currentUser) return
    if (!selectedItemIds.length) return

    const response = await getUserItems({
      userId: currentUser.id,
      perPage: 1,
      currentPage: 0,
      selectedItemId: selectedItemIds[0],
    })

    if ('errors' in response) return

    const transformedItem = transformUserItemsResponse(response)

    updateItemThumbnailsById(transformedItem.map(item => getBumpableItemDataFromModel(item)))
  }, [currentUser, selectedItemIds, updateItemThumbnailsById])

  useEffect(
    function fetchAfterItemUpload() {
      if (!currentUser) return
      if (getSelectedItemThumbnails().find(item => item.id === selectedItemIds[0])) return

      fetchUserItem()
    },
    [fetchUserItem, currentUser, selectedItemIds, getSelectedItemThumbnails],
  )

  const handleAddButtonClick = (event: MouseEvent) => {
    if (!onAddButtonClick) return

    event.preventDefault()
    onAddButtonClick()
  }

  const handleRemoveButtonClick = (item: BumpableItemModel) => () => {
    const updatedItems = filter(selectedItemsThumbnails, prevItem => prevItem.id !== item.id)
    const itemIds = updatedItems.map(({ id }) => id)

    if (item) {
      setSelectedItemIds(itemIds)
    }
  }

  const formatNullPriceLabel = (price: string | JSX.Element, orderItem?: BumpOrderItemModel) => {
    return orderItem && !isOrderLoading ? price : <span className="u-visibility-hidden">-</span>
  }

  const renderOldPrice = (orderItem: BumpOrderItemModel) => {
    const payableAmount = parseFloat(orderItem.payable.amount)
    const discountAmount = parseFloat(orderItem.discount.amount)

    if (payableAmount !== 0 && discountAmount === 0)
      return <span className="u-visibility-hidden">-</span>

    return (
      <>
        <Spacer size={Spacer.Size.Small} />
        <Text
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
          type={Text.Type.Subtitle}
          strikethrough
          text={formatCurrencyAmount(orderItem.total, locale)}
        />
      </>
    )
  }

  const renderItemPrice = (orderItem?: BumpOrderItemModel) => {
    const orderPrice = orderItem ? formatCurrencyAmount(orderItem.payable, locale) : ''
    const orderPriceLabel = formatNullPriceLabel(orderPrice, orderItem)
    const orderOldPrice = orderItem ? renderOldPrice(orderItem) : ''
    const orderOldPriceLabel = formatNullPriceLabel(orderOldPrice, orderItem)

    return (
      <>
        <Spacer />
        <Text
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
          type={Text.Type.Subtitle}
          theme="amplified"
          text={orderPriceLabel}
        />
        {orderOldPriceLabel}
      </>
    )
  }

  const renderItems = () => {
    const itemPricing = keyBy(orderItems, 'itemId')

    return selectedItemsThumbnails.map(item => {
      const { id: itemId, thumbnail: itemPhoto, title: itemTitle } = item

      const bumpOrderPrice = itemPricing[itemId]
      const orderPriceLabel = bumpOrderPrice
        ? formatCurrencyAmount(bumpOrderPrice.payable, locale)
        : ''

      return (
        <HorizontalScrollArea.Item key={itemId}>
          {selectedItemsThumbnails.length === 1 ? (
            <Image
              src={itemPhoto}
              size={Image.Size.X3Large}
              styling={Image.Styling.Rounded}
              alt={`${itemTitle} ${orderPriceLabel}`}
            />
          ) : (
            <div className="bump-items_content-overlay">
              {canAddItems && (
                <button
                  type="button"
                  className={classNames('button-overlay', isDarkMode && 'button-overlay--dark')}
                  onClick={handleRemoveButtonClick(item)}
                  data-testid={`bump-remove-item-button-${itemId}`}
                  aria-label={translate('a11y.actions.remove_item')}
                >
                  <Icon
                    name={X12}
                    color={isDarkMode ? Icon.Color.GreyscaleLevel7 : Icon.Color.GreyscaleLevel1}
                  />
                </button>
              )}
              <Image
                src={itemPhoto}
                size={Image.Size.X3Large}
                styling={Image.Styling.Rounded}
                alt={`${item.title} ${orderPriceLabel}`}
              />
            </div>
          )}
          {renderItemPrice(bumpOrderPrice)}
        </HorizontalScrollArea.Item>
      )
    })
  }

  const renderAddButton = () => {
    const itemIds = selectedItemsThumbnails.map(({ id }) => id)
    const multipleItemSelectionUrl = urlWithParams(BUMP_MULTIPLE_ITEM_SELECTION_URL, {
      item_ids: itemIds,
    })

    if (!canAddItems) return null

    return (
      <div className="u-flexbox u-align-items-center">
        <a
          className="u-flexbox"
          href={multipleItemSelectionUrl}
          onClick={handleAddButtonClick}
          data-testid="bump-add-more-items-button"
        >
          <Icon
            name={PlusSquareOutlined24}
            color="parent"
            aria={{ 'aria-label': translate('a11y.actions.add_items') }}
          />
        </a>
      </div>
    )
  }

  return (
    <div className="u-ui-padding-horizontal-large u-padding-top-large">
      <div className="u-flexbox u-align-items-center">
        <div className="u-fill-width u-text-right u-nowrap u-overflow-hidden">
          <HorizontalScrollArea showControls>
            {renderItems()}
            {canAddItems && (
              <HorizontalScrollArea.Item key="add-more-items">
                <div className="u-flexbox u-fill-height u-align-items-center u-ui-padding-horizontal-x-large">
                  {renderAddButton()}
                </div>
              </HorizontalScrollArea.Item>
            )}
          </HorizontalScrollArea>
        </div>
      </div>
    </div>
  )
}

export default ItemSelection
