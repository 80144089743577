'use client'

import { useIntl } from 'react-intl'
import { Button, Cell, Icon, Spacer, Text } from '@vinted/web-ui'
import { InfoCircle16 } from '@vinted/monochrome-icons'

import SeparatedList from 'components/SeparatedList'
import useTranslate from 'hooks/useTranslate'

import { formatCurrencyAmount } from 'libs/utils/formatString'
import { BumpOptionModel, BumpOrderModel } from 'types/models'

type Props = {
  order: BumpOrderModel
  selectedOption: BumpOptionModel
  onShowSalesTaxModal: () => void
}

const OrderSummary = ({ order, selectedOption, onShowSalesTaxModal }: Props) => {
  const translate = useTranslate('bump_items.order_confirmation_modal.order_summary')

  const { locale } = useIntl()

  function renderPrice() {
    return (
      <Cell styling={Cell.Styling.Tight} suffix={formatCurrencyAmount(order.total, locale)}>
        <Text text={selectedOption.description} />
      </Cell>
    )
  }

  function renderDiscount() {
    const discountAmount = parseFloat(order.discount.amount)

    if (discountAmount <= 0) return null

    return (
      <Cell
        styling={Cell.Styling.Tight}
        suffix={`${formatCurrencyAmount(order.discount, locale, { formatAsNegative: true })}`}
      >
        <Text
          theme="success"
          text={translate('discount', {
            percentage: order.discountPercentage,
          })}
        />
      </Cell>
    )
  }

  function renderFreeBumpValue() {
    const freeBumpValue = parseFloat(order.freePushUpsValue.amount)

    if (freeBumpValue <= 0) return null

    return (
      <Cell
        styling={Cell.Styling.Tight}
        suffix={`${formatCurrencyAmount(order.freePushUpsValue, locale, {
          formatAsNegative: true,
        })}`}
      >
        <Text
          text={translate('free_push_ups', { count: order.freeCount }, { count: order.freeCount })}
        />
      </Cell>
    )
  }

  function renderSalesTax() {
    const { salesTax } = order

    if (!salesTax?.amount) return null

    return (
      <>
        <Spacer size={Spacer.Size.XLarge} />
        <Cell
          styling={Cell.Styling.Tight}
          suffix={`${formatCurrencyAmount(salesTax, locale)}`}
          testId="bump-order-summary-sales-tax"
        >
          <div className="u-flexbox u-align-items-center">
            <Text text={translate('sales_tax')} testId="bump-order-summary-sales-tax--title" />
            <Button
              icon={
                <Icon
                  name={InfoCircle16}
                  color={Icon.Color.GreyscaleLevel3}
                  aria={{
                    'aria-label': translate('a11y.sales_tax_info'),
                  }}
                />
              }
              inline
              size={Button.Size.Small}
              styling={Button.Styling.Flat}
              onClick={onShowSalesTaxModal}
              testId="bump-order-summary-sales-tax--info-circle"
            />
          </div>
        </Cell>
        {order.isTaxCovered && (
          <Cell
            styling={Cell.Styling.Tight}
            body={
              <Text
                text={translate('sales_tax_covered_by_vinted')}
                highlightTheme="amplified"
                type={Text.Type.Subtitle}
                testId="bump-order-summary-sales-tax--covered-by-vinted"
              />
            }
          />
        )}
      </>
    )
  }

  function renderTotal() {
    return (
      <>
        <Spacer size={Spacer.Size.XLarge} />
        <Cell
          styling={Cell.Styling.Tight}
          title={translate('total')}
          suffix={
            <Text type={Text.Type.Title} text={formatCurrencyAmount(order.payable, locale)} />
          }
        />
      </>
    )
  }

  return (
    <Cell>
      <SeparatedList separator={<Spacer size={Spacer.Size.Small} />}>
        {renderPrice()}
        {renderDiscount()}
        {renderFreeBumpValue()}
      </SeparatedList>
      {renderSalesTax()}
      {renderTotal()}
    </Cell>
  )
}

export default OrderSummary
